<template>
  <div class="flex justify-center flex-grow w-9/12 mx-auto p-6 mb-10">
    <div class="mt-5 w-full min-h-56 max-h-full overflow-auto">
    <h4 class="mt-5">Reklamationen</h4>
    <table class="min-w-full leading-normal">
      <thead>
      <tr>
        <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
                text-gray-600 tracking-wider">
          Auftragsnummer
        </th>
        <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
                text-gray-600 tracking-wider">
          Typ
        </th>
      </tr>
      </thead>
      <tbody class="w-full">
      <tr v-if="orderReturns.data.length === 0">
        <td colspan="2" class="px-5 py-2 border-b border-gray-200 bg-white text-center
          text-sm">
          Keine Daten vorhanden</td>
      </tr>
      <tr
        v-for="orderReturn in orderReturns.data" :key="orderReturn.id">
        <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm">
          {{ orderReturn.order.pl_id }}</td>
        <td class="px-5 py-2 border-b border-gray-200
          bg-white text-sm">
          {{ orderReturn.type }}
        </td>
      </tr>
      </tbody>
    </table>
    <div class="mt-2 float-right inline-flex items-center">
      <a href="" @click.prevent="toPage = 1; index()" :class="[orderReturns.current_page <= 1 ? 'text-gray-200': '']"><svg class="w-4 h-4 mt-1 mr-2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="step-backward" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M64 468V44c0-6.6 5.4-12 12-12h48c6.6 0 12 5.4 12 12v176.4l195.5-181C352.1 22.3 384 36.6 384 64v384c0 27.4-31.9 41.7-52.5 24.6L136 292.7V468c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12z"></path></svg></a>
      <a href="" @click.prevent="toPage = prevPage; index()" :class="[orderReturns.current_page <= 1 ? 'text-gray-200': '']"><svg class="w-3 h-3 mt-1 mr-2 transform rotate-180" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="play" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"></path></svg></a>
      <span>Seite {{orderReturns.current_page}} von {{orderReturns.last_page}}</span>
      <a href="" @click.prevent="toPage = nextPage; index()" :class="[orderReturns.current_page >= orderReturns.last_page ? 'text-gray-200': '']"><svg class="w-3 h-3 mt-1 ml-2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="play" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"></path></svg></a>
      <a href="" @click.prevent="toPage = orderReturns.last_page; index()" :class="[orderReturns.current_page >= orderReturns.last_page ? 'text-gray-200': '']"><svg class="w-4 h-4 mt-1 ml-2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="step-forward" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M384 44v424c0 6.6-5.4 12-12 12h-48c-6.6 0-12-5.4-12-12V291.6l-195.5 181C95.9 489.7 64 475.4 64 448V64c0-27.4 31.9-41.7 52.5-24.6L312 219.3V44c0-6.6 5.4-12 12-12h48c6.6 0 12 5.4 12 12z"></path></svg></a>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'Reklamation.vue',
  data() {
    return {
      orderReturns: {
        data: [],
      },
      toPage: 1,
    };
  },
  computed: {
    nextPage() {
      const next = this.orderReturns.current_page + 1;
      if (next >= this.orderReturns.last_page) {
        return this.orderReturns.last_page;
      }
      return next;
    },
    prevPage() {
      const prev = this.orderReturns.current_page - 1;
      if (prev <= 1) {
        return 1;
      }
      return prev;
    },
  },
  methods: {
    index() {
      this.$axios.get('order-returns',
        {
          params: { page: this.toPage },
          headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` },
        })
        .then((response) => {
          this.orderReturns = response.data;
        })
        .catch((e) => {
          this.loadState = 'error';
          this.$toast.open({
            message: e,
            type: 'error',
          });
        });
    },
  },
  mounted() {
    this.index();
  },
};
</script>

<style scoped>

</style>
